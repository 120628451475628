var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('products.title'),"btn-title":_vm.$t('products.new_btn'),"route-name":'products-create'}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent,"btn-title":_vm.$t('products.new_btn'),"btn":true,"has-order":true},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData,"btnAction":_vm.goToCreate}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 "},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[
              { name: 'name' },
              { name: 'price' },

              { name: 'status' },
              { name: 'action' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('name-tile',{attrs:{"name":data.item.name,"image":data.item.image,"data-id":data.item.id}})]}},{key:"price",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":data.item.price,"dis-price":data.item.price}})]}},{key:"status",fn:function(ref){
            var data = ref.data;
return [_c('wameed-switch',{attrs:{"checked":data.item.status ===1},on:{"onChange":function (){ return _vm.onChange(data.item); }}})]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticClass:"table-option order-handler",class:{'disabled':!_vm.canOrderItems},attrs:{"title":!_vm.canOrderItems?_vm.$t('common.can_not_order'):''}},[_c('div',{staticClass:"order"},[_c('o-order-icon')],1)]),_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.goToUpdate(data.item.id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('edit-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('btn.edit'))+" ")],1)]),_c('b-dropdown-item',{on:{"click":function($event){_vm.deleteModal = true; _vm.currentItemData = data.item}}},[_c('span',{staticClass:"text-regular-14  text-danger"},[_c('trash-icon',{staticClass:"mx-2 "}),_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")],1)])],1)]}}],null,false,1164682975)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"items":_vm.paginationDropDownItems,"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)]),_c('warning-modal',{attrs:{"variant":"danger","iconName":"trash-icon","visible":_vm.deleteModal,"title":_vm.$t('products.modal.delete'),"subTitle":_vm.$t('products.modal.delete_desc'),"btnTitle":_vm.$t('btn.delete')},on:{"close":function($event){_vm.deleteModal = false},"submitAction":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }