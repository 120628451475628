<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('products.title')"
        :btn-title="$t('products.new_btn')"
        :route-name="'products-create'"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :btn-title="$t('products.new_btn')"

          :btn="true"
          :has-order="true"
          @btnAction="goToCreate"

      >
      </filter-header>
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'name' },
                { name: 'price' },

                { name: 'status' },
                { name: 'action' },
              ]"
            >

              <template slot="name" slot-scope="{ data }">
                <name-tile
                    :name="data.item.name"
                    :image="data.item.image"
                    :data-id="data.item.id"
                />
              </template>


              <template slot="price" slot-scope="{ data }">
                <check-price
                    :price="data.item.price"
                    :dis-price="data.item.price"
                />
              </template>


              <template slot="status" slot-scope="{ data }">
                <wameed-switch
                    :checked="data.item.status ===1"
                    @onChange="()=>onChange(data.item)"/>
              </template>

              <template slot="action" slot-scope="{ data }">

                <div
                    v-b-tooltip.html
                    :title="!canOrderItems?$t('common.can_not_order'):''"
                    class="table-option order-handler"
                    :class="{'disabled':!canOrderItems}"
                >
                  <div class="order">

                    <o-order-icon/>
                  </div>
                </div>

                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      :to="goToUpdate(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      @click="deleteModal = true; currentItemData = data.item">
                    <span class="text-regular-14  text-danger">
                      <trash-icon class="mx-2 "/>
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              :items="paginationDropDownItems"
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>


    <warning-modal
        variant="danger"
        iconName="trash-icon"
        :visible="deleteModal"
        @close="deleteModal = false"
        @submitAction="deleteAction"
        :title="$t('products.modal.delete')"
        :subTitle="$t('products.modal.delete_desc')"
        :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckValue from "@/views/pages/products/components/checkValue";
import CheckPrice from "@/views/pages/products/components/checkPrice";
import NameTile from "@/components/nameTile.table";
import Sortable from "sortablejs";
import WarningModal from "@/components/WarningModal";

export default {
  components: {
    NameTile,
    CheckPrice,
    CheckValue,
    PageHeader,
    WarningModal,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      vendor_id: '',
      showModal: false,
      importModal: false,
      deleteModal: false,
      currentItemData: null,
      sortable: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'order',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('common.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          type: 'text',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'active',
              title: this.$i18n.t('common.exist'),
            },
            {
              id: 'not_active',
              title: this.$i18n.t('common.not_exist'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('products.table.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },
        {
          key: 'category',
          label: this.$i18n.t('products.table.category'),
          sortable: false,
          tdClass: 'w-1/8'
        },


        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: false,
          tdClass: 'w-1/8'
        },


        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'vendor/products/getData',
      getTotal: 'vendor/products/getTotal',
    }),
    canOrderItems() {
      return this.filterData.order_by === 'order';
    },
    paginationDropDownItems() {
      return [
        {
          title: `20 - 1 `,
          id: 20,
        },
        {
          title: `40 - 1 `,
          id: 40,
        },
        {
          title: `60 - 1 `,
          id: 60,
        },
        {
          title: this.$i18n.t('common.all'),
          id: this.getTotal.totalItems,
        },
      ];
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'vendor/products/get',
      _deleteData: 'vendor/products/delete',
    }),

    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }
      this.$store.dispatch('vendor/products/toggle', {id: item.id, status: status})
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      this._getData(this.filterData);
    },

    deleteAction() {
      this.deleteModal = false
      this._deleteData(this.currentItemData.id);
    },
    goToUpdate(id) {
      return {
        name: 'products-update',
        params: {lang: this.$i18n.locale, id},
      };
    },
    goToCreate() {
      this.$router.push({
        name: 'products-create',
        params: {lang: this.$i18n.locale, vendor_id: this.vendor_id},
      });
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      if (this.canOrderItems) {
        this.Sortable();
      } else {
        this.SortableDestory();
      }
      this.loadData();
    },

    order(id, oldIndex, newIndex) {
      this.$store.dispatch('vendor/products/reorder', {
        id: id,
        vendor_id: this.vendor_id,
        old_order: oldIndex,
        new_order: newIndex,
        page: this.filterData.page,
        per_page: this.filterData.per_page,
      });
    },

    Sortable() {
      let order = this.order;
      if (this.sortable == null) {
        let el = document.querySelector("tbody[role='rowgroup']");
        // var sortable = Sortable.create(el);
        this.sortable = Sortable.create(el, {
          group: "name",  // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
          easing: "cubic-bezier(1, 0, 0, 1)", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
          handle: ".order-handler",  // Drag handle selector within list items
          disabled: !this.canOrderItems,

          // Element dragging ended
          onEnd: function (/**Event*/evt) {

            console.log(evt)
            let id = evt.item.querySelector('div[data-id]').getAttribute('data-id')
            order(id, evt.oldIndex, evt.newIndex);

          },


        });
      }
    },

    SortableDestory() {
      if (this.sortable != null) {
        this.sortable.save();
        this.sortable.destroy();
        this.sortable = null;
      }
    }
  },

  mounted() {
    this.Sortable();
  },
};
</script>
